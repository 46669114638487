@font-face {
  src: url('./assets/fonts/Nunito-Regular.ttf');
  font-family: Nunito;
}

* {
  font-family: Nunito !important;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edenAlertSuccess {
    color: #fff;
    background-color: green;
}

.edenAlertError {
    color: #fff;
    background-color: red;
}

.edenAlert {
    width: 18rem;
    height: 101%;
    display: flex;
    align-items: center;
    z-index: 2000;
    justify-content: space-between;
    font-family: Nunito;
    border-radius: 6px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
}

.MuiAppBar-colorPrimary {
  background-color: #12154E !important;
}