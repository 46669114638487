.settings {
    width: 30rem;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.settingsSpacing {
    margin-bottom: 1rem;
}

.settingsButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.serviceChargeButtonContainers {
    display: flex;
    justify-content: flex-end;
}

.settingsSubmitButton {
    margin-right: 1rem;
}