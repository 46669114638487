.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loginWrapper {
    width: 20rem;
}

.spacingDown {
    margin-bottom: 1rem;
}

.text-center {
    display: flex;
    justify-content: center;
}